import { put } from "redux-saga/effects";
import * as action_type from "../Constant";
import {
  API_URL,
  AUTH_HEADERS,
  AUTH_MULTYPART_HEADERS,
} from "../../Axios/axiosData";
import axios from "axios";
import { toast } from "react-toastify";

export function* allMsaAgreementTemplate() {
  let data = { response: false, backend_data: null };

  let url = API_URL + `msa/agreement-template/`;

  yield axios.get(url, AUTH_HEADERS()).then((resp) => {
    data.backend_data = resp?.data;
    data.response = true;
  });

  yield put({ type: action_type.SET_ALL_MSA_AGREEMENT_TEMPLATE, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}

export function* deleteMSaTemplates(payload) {

  let data = { response: false, backend_data: null };

  yield axios.delete(API_URL + `msa/agreement-template/${payload?.payload?.id}/`, AUTH_HEADERS())
    .then(resp => {
      data.response = true
      data.backend_data = resp.data

      toast.success("Template Deleted Successfully")

    }).catch((error) => {

      if (error?.response?.data?.message) {
        toast.error(`${error?.response?.data?.message}`)
      }
    })

  if (payload?.payload?.type === 'msa') {
    let url = API_URL + `msa/agreement-template/`;
    yield axios.get(url, AUTH_HEADERS()).then((resp) => {
      data.backend_data = resp?.data;
      data.response = true;
    });

    yield put({ type: action_type.SET_ALL_MSA_AGREEMENT_TEMPLATE, data });
    yield put({ type: action_type.SET_LOADING, payload: false });
  } else {
    let url = API_URL + `msa/agreement-template/?template_type=sow`;

    yield axios.get(url, AUTH_HEADERS())
      .then((resp) => {
        data.backend_data = resp?.data;
        data.response = true;
      });

    yield put({ type: action_type.SET_ALL_SOW_TEMPLATE, data });
    yield put({ type: action_type.SET_LOADING, payload: false });
  }
}


// export function* msaAgreementTemplateById({ payload }) {
//   let data = { response: false, backend_data: null };

//   let url = API_URL + `msa/agreement-template/${payload?.id}/`;

//   yield axios.get(url, AUTH_HEADERS()).then((resp) => {
//     data.backend_data = resp?.data;
//     data.response = true;
//   });

//   yield put({ type: action_type.SET_MSA_AGREEMENT_TEMPLATE_BY_ID, data });
//   yield put({ type: action_type.SET_LOADING, payload: false });
// }
export function* msaAgreementTemplateById({ payload }) {
  try {
    let data = { response: false, backend_data: null };
    let url = API_URL + `msa/agreement-template/${payload?.id}/`;

    const resp = yield axios.get(url, AUTH_HEADERS());

    data.backend_data = resp?.data;
    data.response = true;

    yield put({ type: action_type.SET_MSA_AGREEMENT_TEMPLATE_BY_ID, data });
    yield put({ type: action_type.SET_LOADING, payload: false });

    return data; // Yield the final data object if needed
  } catch (error) {
    console.error('Error fetching agreement template:', error);
    // Handle error scenarios if needed
  }
}

export function* msaDefaultTemplate() {
  yield put({ type: action_type.SET_LOADING, payload: true });

  let data = { response: false, backend_data: null };

  let url = API_URL + `msa/agreement-template/default_template/`;

  yield axios.get(url, AUTH_HEADERS()).then((resp) => {
    data.backend_data = resp?.data;
    data.response = true;
  });

  yield put({ type: action_type.SET_DEFAULT_TEMPLATE, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}

export function* msaFormattedTemplate({ payload }) {
  yield put({ type: action_type.SET_LOADING, payload: false });

  let data = { response: false, backend_data: null };

  let url =
    API_URL + `msa/master-agreement/${payload?.slug}/formatted_template/`;

  yield axios.get(url, AUTH_HEADERS()).then((resp) => {
    data.backend_data = resp?.data;
    data.response = true;
  });

  yield put({ type: action_type.SET_FORMATTED_TEMPLATE, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}

export function* createMsaAgreementTemplate({ payload ,callbacks}) {
  let data = { response: false, backend_data: null };

  yield put({ type: action_type.SET_LOADING, payload: true });
  yield axios
    .post(API_URL + "msa/agreement-template/", payload, AUTH_HEADERS())
    .then((resp) => {
      data.backend_data = resp?.data;
      data.response = true;
      callbacks(resp?.data)
    })
    .catch((err) => { });

  yield axios.get(API_URL + `msa/agreement-template/`, AUTH_HEADERS()).then((resp) => {
    data.backend_data = resp?.data;
    data.response = true;
  });
  
  yield put({ type: action_type.SET_ALL_MSA_AGREEMENT_TEMPLATE, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
 

}

export function* editMsaAgreementTemplate({ payload,callbacks }) {
  let data = { response: false, backend_data: null };

  yield put({ type: action_type.SET_LOADING, payload: true });
  yield axios.put(API_URL + "msa/agreement-template/" + payload.id + "/",payload,AUTH_HEADERS())
    .then((resp) => {
      data.backend_data = resp?.data;
      data.response = true;
      callbacks(resp?.data)
    })
    .catch((err) => { });
  yield put({ type: action_type.SET_LOADING, payload: false });

  let url = API_URL + `msa/agreement-template/`;

  yield axios.get(url, AUTH_HEADERS()).then((resp) => {
    data.backend_data = resp?.data;
    data.response = true;
  });
  
  const res= yield put({ type: action_type.SET_ALL_MSA_AGREEMENT_TEMPLATE, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
 


}

export function* editMsaDefaultAgreementTemplate({ payload }) {
  yield put({ type: action_type.SET_LOADING, payload: true });
  yield axios.patch(API_URL + `msa/master-agreement/${payload?.slug}/`, { template: payload.template }, AUTH_HEADERS())
    .then((resp) => {
    })
    .catch((err) => {
      // if (err.response.data.role_name) {
      //   toast.error("Role Name " + err.response.data.role_name[0]);
      // } else {
      //   toast.error(err.message);
      // }
    });

  yield put({ type: action_type.SET_LOADING, payload: false });
}

export function* sendMsaAgreementTemplate({ payload }) {

  yield put({ type: action_type.SET_LOADING, payload: true });
  yield axios
    .post(API_URL + `settings/trigger-mail/`, payload, AUTH_HEADERS())
    .then((resp) => {
      toast.success(resp?.data?.message);
    })
    .catch((err) => { });

  yield put({ type: action_type.SET_LOADING, payload: false });
}

export function* uploadMsaAgreementMedia(payload) {
  yield axios
    .post(
      API_URL + `msa/agreement-template-media/`,
      payload,
      AUTH_MULTYPART_HEADERS()
    )
    .then((resp) => {
      return resp?.data;
    })
    .catch((err) => { });
}

export function* downloadPDFmsafn(payload) {

  let data = { response: false, backend_data: null };

  yield axios.get(API_URL + `msa/master-agreement/${payload?.payload?.slug}/download_pdf/`, AUTH_MULTYPART_HEADERS())
    .then((resp) => {

      data.backend_data = resp?.data;
      data.response = true;
    })
    .catch((err) => { });

  yield put({ type: action_type.SET_DOWNLOAD_MSA_PDF_DATA, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}


export function* docTypeFileData(payload) {

  let data = { response: false, backend_data: null };

  let url;
  if (payload?.payload?.type === 'msa') {
    url = API_URL + `msa/master-agreement/${payload?.payload?.slug}/download_document/`
  } else {
    url = API_URL + `sow/statement-of-work/${payload?.payload?.slug}/download_document/`
  }

  yield axios.get(url, AUTH_MULTYPART_HEADERS())
    .then((resp) => {

      data.backend_data = resp?.data;
      data.response = true;
    })
    .catch((err) => { });

  yield put({ type: action_type.SET_DOC_TYPE_CONTENT, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}

export function* changeDraftPrintDownloadData(payload) {

  let data = { response: false, backend_data: null };

  yield axios.get(API_URL + `msa/master-agreement/${payload?.payload}/update-templates/`, AUTH_MULTYPART_HEADERS())
    .then((resp) => {

      data.backend_data = resp?.data;
      data.response = true;
    })
    .catch((err) => { });

  yield put({ type: action_type.SET_LOADING, payload: false });
}

export function* downloadPDFsowfn(payload) {

  let data = { response: false, backend_data: null };

  yield axios.get(API_URL + `sow/statement-of-work/${payload?.payload?.slug}/download_pdf/`, AUTH_MULTYPART_HEADERS())
    .then((resp) => {

      data.backend_data = resp?.data;
      data.response = true;
    })
    .catch((err) => { });

  yield put({ type: action_type.SET_DOWNLOAD_SOW_PDF_DATA, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}


// sow template editor

export function* sowFormattedTemplateData({ payload }) {

  yield put({ type: action_type.SET_LOADING, payload: false });

  let data = { response: false, backend_data: null };

  let url = API_URL + `sow/statement-of-work/${payload?.id}/formatted_template/`;

  yield axios.get(url, AUTH_HEADERS())
    .then((resp) => {
      data.backend_data = resp?.data;
      data.response = true;
    });

  yield put({ type: action_type.SET_SOW_FORMATTED_TEMPLATE, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}

export function* allSowTemplateListData() {

  yield put({ type: action_type.SET_LOADING, payload: false });

  let data = { response: false, backend_data: null };

  let url = API_URL + `msa/agreement-template/?template_type=sow`;

  yield axios.get(url, AUTH_HEADERS())
    .then((resp) => {
      data.backend_data = resp?.data;
      data.response = true;
    });

  yield put({ type: action_type.SET_ALL_SOW_TEMPLATE, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}

export function* changeSowTemplateData({ payload }) {

  yield put({ type: action_type.SET_LOADING, payload: true });

  yield axios.patch(API_URL + `sow/statement-of-work/${payload?.slug}/`, { template: payload.template }, AUTH_HEADERS())
    .then((resp) => {
    })
    .catch((err) => {
      console.log("Sow Error", err);
    });

  yield put({ type: action_type.SET_LOADING, payload: false });
}