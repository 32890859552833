import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'react-input-range/lib/css/index.css'
// import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import RootStore from './Store/RootStore'
import Loader from './Components/Home/Pages/Loader';
import { ToastContainer, toast } from "react-toastify";
const App = React.lazy(() => import('./App'));


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={RootStore} >
    <BrowserRouter>
      <Suspense fallback={<Loader />}>
        <App />
        {/* <ToastContainer /> */}
      </Suspense>
    </BrowserRouter>
  </Provider>
);


reportWebVitals();
